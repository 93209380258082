<template>
    <div class="Home" v-cloak>
      <organiz-header v-if="$store.getters.isLoggedIn"
               v-show="currentUser.authorities[0].authority === 'ROLE_ADMIN' ||
                currentUser.authorities[0].authority === 'ROLE_PROFESSOR'"/>
      <student-header v-if="$store.getters.isLoggedIn" v-show="currentUser.authorities[0].authority === 'ROLE_USER'"/>

      <main>
        <section class="uk-section uk-section-small">
          <div class="uk-container">

            <div class="uk-text-center uk-margin">
              <ul class="uk-breadcrumb uk-flex-center uk-margin-remove">
                <li>
                  <a href>Home</a>
                </li>
                <li>
                  <span>{{$t("additional.assess")}}</span>
                </li>
              </ul>
              <!--<h1 class="uk-margin-small-top uk-margin-remove-bottom">Student register</h1>-->
            </div>

            <div class="uk-grid-medium" uk-grid>
              <div class="uk-width-1-1 uk-width-1-4@m tm-aside-column">
                <app-my-navmenu
                ></app-my-navmenu>
              </div>
              <div class="uk-width-1-1 uk-width-expand@m">
                <div class="uk-card uk-card-default uk-card-small tm-ignore-container">
                  <header class="uk-card-header">
                    <h2 class="uk-h2"><span class="uk-align-left">{{$t("additional.assess")}}</span>
                      <span class="uk-align-right"><span
                        class="uk-text-primary">Time</span> {{minutes}}:{{seconds}}</span>
                    </h2>


                  </header>
                  <section class="uk-card-body">
                    <div class="row">
                      <div>
                        <!--<h1 v-if="questionIndex < 4">Question : {{ questionIndex+1}}</h1>-->

                        <div class="callout">

                          <div v-for="(question, index) in quiz.questions" :key="question">

                            <div v-show="index === questionIndex">
                              <h3> {{questionIndex+1}} . <span v-html="question.question"></span>
                                <!--{{question.level}}-->
                              </h3>
                              <ul class="uk-list uk-list-divide">

                                <li v-for="response in question.quizAnswers" :key="response">

                                  <label>
                                    <input class="uk-radio" type="radio"
                                           :value="response"
                                           :name="index"
                                           v-model="userResponses[index]"/> <span v-html="response.anwer"></span>
                                    <!--{{response.flag}}-->
                                  </label>
                                </li>

                              </ul>
                              <hr>
                              <!-- The two navigation buttons -->
                              <!-- Note: prev is hidden on first question -->
                              <button type="button" class="uk-button uk-button-secondary uk-align-left uk-hidden"
                                      v-if="questionIndex > 0"
                                      v-on:click="prev">
                                prev
                              </button>
                              <button type="button" class="uk-button uk-button-primary uk-align-right" v-on:click="next">
                                next
                              </button>
                            </div>


                            <!--maxindex:{{maxindex}} index:{{index}}-->
                          </div>

                          <div v-show="questionIndex === maxindex"><h2>Thank you.</h2></div>

                          <!-- Last page, quiz is finished, display result -->
                          <!--<div v-show="questionIndex === quiz.questions.length">-->
                          <div v-if="questionIndex == 10">
                            <h3 class="uk-text-center">당신의 레벨은 <span class="uk-text-success"><span
                              v-if="score>=0&& score<4">1</span>
                            <span v-else-if="score<7 && score>=4">2</span>
                              <span v-else-if="score<11 && score>=7">3</span>
                              <span v-else-if="score<14 && score>=11">4</span>
                              <span v-else-if="score>=14">5</span>
                            </span> 입니다.
                            </h3>
                            <div class="uk-flex uk-flex-center">
                              <ul class="quizresult">
                                <li v-for="(item, index) in quiz.questions" :key="item">
                                  <div>{{index+1}}<span v-if="item.level === 1">(하)</span><span
                                    v-else-if="item.level === 2">(중)</span><span v-else-if="item.level === 3">(상)</span>
                                  </div>
                                  <div><span v-if="userResponses[index].flag === 'Y'">O</span>
                                    <span v-else>X</span>

                                  </div>
                                </li>

                              </ul>
                            </div>
                            <hr>
                            <button type="button" class="uk-button uk-button-primary uk-align-right" @click="resume">
                              Continue
                            </button>
                          </div>

                        </div>

                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <organiz-footer/>
    </div>
</template>


<script>

  import QuizService from '@/services/QuizService'
  import {mapGetters} from 'vuex'
  import MyNav from '@/components/GlobViews/MyNavmenu'
  import moment from 'moment'

  import OrganizHeader from '@/components/Organizcmp/OrganizHeader.vue'
  import OrganizFooter from '@/components/Organizcmp/OrganizFooter.vue'
  import StudentHeader from '@/components/Studentcmp/StudentHeaderNew.vue'

  export default {
    name: 'Home',
    components: {
      appMyNavmenu: MyNav,
      OrganizHeader,
      OrganizFooter,
      StudentHeader,
    },
    data() {
      return {
        layout: 'OrganizLayout',
        isContinue: false,
        questionIndex: 0,
        userResponses: [],
        quiz: {
          title: '',
          questions: [],
          questions1: [],
          lastquestions: []
        },
        currentLevel: 1,
        score: 0,
        lastYcount: 0,
        lastNcount: 0,
        time: 0,
        isRunning: false,
        interval: null,
        hours: 0,
        minutes: 0,
        seconds: 0,
        maxindex: 11,
        lastuserLevel: 0
      }
    },
    computed: {
      ...mapGetters(['isLoggedIn', 'currentUser'])
    },
    async mounted() {
      this.getQuizs()
      this.toggleTimer()
    },
    filters: {
      moment: function (date) {
        return moment(date).format('YYYY.MM.DD')
        // return moment(date).format('YYYY-MM-DD, h:mm:ss a')
      },
      timefilter: function (value) {
        if (value) {
          return moment(String(value)).format('mm:ss a')
        }

      }
    },

    created() {
      if (this.currentUser.authorities[0].authority === 'ROLE_USER'
      ) {
        this.layout = "StudentLayout";
      } else {
        this.layout = "OrganizLayout";
      }
      //alert(this.layout)
    },
    methods: {

      toggleTimer() {
        if (this.isRunning) {
          clearInterval(this.interval);
        } else {
          this.interval = setInterval(this.incrementTime, 1000);
        }
        this.isRunning = !this.isRunning
      },
      incrementTime() {
        this.time = parseInt(this.time) + 1;
        this.hours = Math.floor(this.time / 3600);
        this.time %= 3600;
        this.minutes = Math.floor(this.time / 60);
        this.seconds = this.time % 60;
      },
      async getQuizs() {
        try {

          const response = await QuizService.getQuizs({})
          this.quiz.questions1 = response.data
          this.quiz.questions1 = this.randomList(this.quiz.questions1)

//level buriin hamgiin svvliin asultudig ylgaj awna
          for (let i = 0; i < this.quiz.questions1.length; i++) {

            if (this.quiz.questions1[i].id == 11 || this.quiz.questions1[i].id == 20 || this.quiz.questions1[i].id == 27) {
              this.quiz.lastquestions.push(this.quiz.questions1[i])
              this.quiz.questions1.splice(i, 1);
            }
          }

          for (let i = 0; i < this.quiz.questions1.length; i++) {

            if (this.quiz.questions1[i].level === this.currentLevel) {
              this.quiz.questions.push(this.quiz.questions1[i])
              this.quiz.questions1.splice(i, 1);
              break;
            }
          }
        } catch (error) {
          this.error = error.response.data.error
        }
      },

      confirm() {
        try {

          var level = 0


          if (this.score >= 0 && this.score < 4) {
            level = 1
          }
          if (this.score < 7 && this.score >= 4) {
            level = 2
          }
          if (this.score < 11 && this.score >= 7) {
            level = 3
          }
          if (this.score < 14 && this.score >= 11) {
            level = 4
          }
          if (this.score >= 14) {
            level = 5
          }
          //eniig suuliin 3 asuult ilgeehdee useriin leveliig hadgalj awaad dahin ilgeehed ashiglana
          this.lastuserLevel = level
          var results = []
          for (let i = 0; i < this.quiz.questions.length; i++) {
            let item = {}
            item.quizId = this.quiz.questions[i].id
            item.quizAnswerId = this.userResponses[i].id
            item.userLevel = level
            item.time = this.time
            results.push(item)
          }
          this.sendResult(results)
        } catch (error) {
          this.error = error.response.data.error
        }
      },

      async sendResult(results) {
        const response = await QuizService.setQuizResult(results)

        if (response.data.success) {
          alert(response.data.message)
        }
      },

      resume() {
        // level1 - 11 level2 - 20 level3 - 27
        this.questionIndex = 0
        this.userResponses = []
        this.quiz.questions = []
        this.maxindex = 3

        this.currentLevel = 1
        this.isContinue = true
        this.quiz.questions1 = this.quiz.lastquestions
        for (let i = 0; i < this.quiz.questions1.length; i++) {
          if (this.quiz.questions1[i].level === this.currentLevel) {
            this.quiz.questions.push(this.quiz.questions1[i])
            this.quiz.questions1.splice(i, 1);
            break;
          }
        }
      },

      randomList: function (rand) {
        return rand.sort(function () {
          return 0.5 - Math.random()
        });
      },

      // Go to next question
      next: function () {
        // alert(this.userResponses[this.questionIndex].flag)


        if (this.userResponses[this.questionIndex] == null) {
          alert("Please choose answer.");
          return
        }
        if (this.isContinue === false) {
          if (this.userResponses.length > 1) {
            if (this.userResponses[this.userResponses.length - 1].flag == 'Y') {
              this.lastYcount = 0;
              for (let i = 0; i < this.userResponses.length; i++) {
                if (this.userResponses[this.userResponses.length - (i + 1)].flag == 'Y') {
                  this.lastYcount++;
                } else {
                  break;
                }
              }
              // alert(lastYcount)

              if (this.lastYcount % 2 === 0 && this.currentLevel < 3) {

                this.currentLevel++;
              }
            }

            if (this.userResponses[this.userResponses.length - 1].flag == 'N') {
              this.lastNcount = 0;
              for (let i = 0; i < this.userResponses.length; i++) {
                if (this.userResponses[this.userResponses.length - (i + 1)].flag == 'N') {
                  this.lastNcount++;
                } else {
                  break;
                }
              }
              // alert(lastNcount)

              if (this.lastNcount % 2 === 0 && this.currentLevel > 1) {
                this.currentLevel--;
              }
            }


            if (this.userResponses.length == 8) {
              let ismedium = false
              for (let i = 0; i < this.quiz.questions.length; i++) {
                if (this.quiz.questions[i].level == 2) {
                  ismedium = true
                  break
                }
              }
              if (!ismedium) {
                this.currentLevel = 2
              }
            }

            if (this.userResponses.length == 9) {
              let islow = false
              for (let i = 0; i < this.quiz.questions.length; i++) {
                if (this.quiz.questions[i].level == 3) {
                  islow = true
                  break
                }
              }
              if (!islow) {
                this.currentLevel = 3
              }
            }
          }

        } else {
          this.currentLevel++;


        }


        if (this.quiz.questions1.length > 0 && this.quiz.questions.length < 10) {
          this.quiz.questions1 = this.randomList(this.quiz.questions1)
          for (let i = 0; i < this.quiz.questions1.length; i++) {
            if (this.quiz.questions1[i].level === this.currentLevel) {
              this.quiz.questions.push(this.quiz.questions1[i])
              this.quiz.questions1.splice(i, 1);
              break;
            }
          }
        }

        if (this.questionIndex == 9) {
          this.scoree();
          this.confirm();
          this.toggleTimer()
        }

        this.questionIndex++;
        // console.log(this.userResponses);

        if (this.questionIndex === this.maxindex) {
          let results = []
          for (let i = 0; i < this.quiz.questions.length; i++) {
            let item = {}
            item.quizId = this.quiz.questions[i].id
            item.quizAnswerId = this.userResponses[i].id
            item.userLevel = this.lastuserLevel
            item.time = 0
            results.push(item)
          }

          this.sendResult(results)


        }
      },
      // Go to previous question
      prev: function () {
        this.questionIndex--;
        // this.userResponses[this.questionIndex] = null
      }
      ,
      scoree() {

        for (let i = 0; i < this.userResponses.length; i++) {
          if (this.userResponses[i].flag === 'Y') {
            this.score = this.score + this.userResponses[i].score
          }
        }
      }
    }
  }
</script>

<style scoped>

  .quizresult {
    list-style: none;
    width: 300px;
    padding: 0;
    text-align: center;
  }

  .quizresult li {
    background: #fff;
    display: inline-block;
    color: #fff;
    margin-bottom: 5px;
    margin-right: 3px;
  }

  .quizresult li div:first-child {
    background: rgba(14, 109, 205, 0.69);
    color: #fff;
    border-bottom: 2px solid #fff;
    text-align: center;
    padding: 5px;
    width: 45px;
  }

  .quizresult li div:last-child {
    background: rgba(109, 154, 187, 0.68);
    color: #fff;
    text-align: center;
    padding: 5px;
    width: 45px;
  }


</style>

import Api from '@/services/Api'

const resource = '/quiz'

export default {

  getQuizs(params = {}) {
    return Api().get(`${resource}/quizs`, {
      params: params
    })
  },
  setQuizResult(credentials) {
    return Api().post(`${resource}/resultset`, credentials)
  },
  getQuizInfo(params = {}) {
    return Api().get(`${resource}/totalinfo`, {
      params: params
    })
  },
  getQuizInfoMain(params = {}) {
    return Api().get(`${resource}/totalinfo2`, {
      params: params
    })
  },
  getQuizsByPage(params = {}) {
    return Api().get(`${resource}/page/quizs?size=12`, {
      params: params
    })
  },
}
